// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-weddings-abigail-caio-js": () => import("./../src/pages/weddings/abigail-caio.js" /* webpackChunkName: "component---src-pages-weddings-abigail-caio-js" */),
  "component---src-pages-weddings-marlena-kyle-js": () => import("./../src/pages/weddings/marlena-kyle.js" /* webpackChunkName: "component---src-pages-weddings-marlena-kyle-js" */),
  "component---src-pages-weddings-olivia-tj-js": () => import("./../src/pages/weddings/olivia-tj.js" /* webpackChunkName: "component---src-pages-weddings-olivia-tj-js" */),
  "component---src-pages-weddings-roshni-allwyn-js": () => import("./../src/pages/weddings/roshni-allwyn.js" /* webpackChunkName: "component---src-pages-weddings-roshni-allwyn-js" */),
  "component---src-pages-weddings-sirley-lamont-js": () => import("./../src/pages/weddings/sirley-lamont.js" /* webpackChunkName: "component---src-pages-weddings-sirley-lamont-js" */)
}

